cei.page.home = (function () {
    let imageSize = {};
    let isDesktop = false;
    let isMobileLandscape = false;
    let isMobilePortrait = false;
    let isTabletLandscape = false;
    let isTabletPortrait = false;

    /**
     * @description Get selectors for all images by display mode
     * @example cei.page.home.getImageSelectors()
     * @returns {string[]} selectors for images based on display modes
     */
    function getImageSelectors() {
        const displayModes = [
            'block:not([class*="displaymode-"])',
            'displaymode-full',
            'displaymode-half',
            'displaymode-one-quarter',
            'displaymode-one-third',
            'displaymode-three-quarters',
            'displaymode-two-thirds',
            'mt_slide__img'
        ];

        return displayModes.map(mode => `.${mode} img`);
    }

    /**
     * @description Get the image size values
     * @example cei.page.home.getImageSizes()
     * @returns {number[]} array of image size values
     */
    function getImageSizes() {
        return Object.values(imageSize);
    }

    /**
     * @description Event handler
     * @example cei.page.home.handleEvent()
     */
    function handleEvent() {
        setDeviceFlags();
        setImageWidths();
        setImageUrls();
    }

    /**
     * @description Set device flags
     * @example cei.page.home.setDeviceFlags()
     */
    function setDeviceFlags() {
        const mobileRegex = /Android|webOS|iP(hone|od)|BlackBerry|IEMobile|Opera Mini/i;
        const tabletRegex = /(Android(?!.*mobile))|iPad|Kindle|Playbook|Silk|(Puffin(?!.(IP|AP|WP)))|Tablet|(Windows(?!.*phone)(?!.*NT)(.touch))/i;
        const userAgent = window.navigator.userAgent;
        const isMobile = mobileRegex.test(userAgent);
        const isTablet = tabletRegex.test(userAgent);
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        const isLandscape = viewportWidth > viewportHeight;

        isDesktop = !isMobile && !isTablet;
        isMobileLandscape = isMobile && isLandscape;
        isMobilePortrait = isMobile && !isLandscape;
        isTabletLandscape = isTablet && isLandscape;
        isTabletPortrait = isTablet && !isLandscape;
    }

    /**
     * @description Set image urls
     * @example cei.page.home.setImageUrls()
     */
    function setImageUrls() {
        const imageSelectors = getImageSelectors();
        const imageSizes = getImageSizes();

        imageSelectors.forEach((imageSelector) => {
            imageSize = imageSizes.shift();

            document.querySelectorAll(imageSelector).forEach((imageElement) => {
                const urlAttribute = imageElement.hasAttribute('src') ? 'src' : 'data-src';
                let url = imageElement.getAttribute(urlAttribute);

                if (url && ['/products/', '/homepage-images/'].some(substring => url?.includes(substring))) {
                    const resizerParam = '?width=';
                    const newUrl = url.includes('?') ? url.replace(/\?width=\d+/, `${resizerParam}${imageSize}`) : `${url}${resizerParam}${imageSize}`;

                    if (imageElement.hasAttribute('src')) {
                        imageElement.setAttribute('src', newUrl);
                    }
                    if (imageElement.hasAttribute('data-src')) {
                        imageElement.setAttribute('data-src', newUrl);
                    }
                }
            });
        });
    }

    /**
     * @description Set image widths
     * @example cei.page.home.setImageWidths()
     */
    function setImageWidths() {
        const imageSizes = {
            desktopTabletLandscape: {
                auto: 1140,
                full: 1140,
                half: 555,
                quarter: 263,
                third: 360,
                threeQuarters: 848,
                twoThirds: 750,
                mt: 175
            },
            mobileLandscapeTabletPortrait: {
                auto: 720,
                full: 720,
                half: 345,
                quarter: 345,
                third: 220,
                threeQuarters: 720,
                twoThirds: 470,
                mt: 96
            },
            mobilePortrait: {
                auto: 384,
                full: 384,
                half: 384,
                quarter: 177,
                third: 384,
                threeQuarters: 384,
                twoThirds: 384,
                mt: 131
            }
        };

        if (isMobilePortrait) {
            Object.assign(imageSize, imageSizes.mobilePortrait);
        } else if (isDesktop || isTabletLandscape) {
            Object.assign(imageSize, imageSizes.desktopTabletLandscape);
        } else if (isMobileLandscape || isTabletPortrait) {
            Object.assign(imageSize, imageSizes.mobileLandscapeTabletPortrait);
        }
    }
    
    document.addEventListener('DOMContentLoaded', handleEvent);
    window.addEventListener('resize', handleEvent);
    
})();
